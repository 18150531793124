// Utility functions for calculations

/**
 * Calculate rewards for a given credit card based on spending patterns and mile value
 */
export const calculateCardRewards = (card, spending, mileValue) => {
  let totalCashback = 0;
  let totalMiles = 0;
  let totalValue = 0; // Combined value in S$
  const categoryRewards = {};
  let reachedLimit = false;
  let combinedLimitUsed = 0; // Track combined limit usage

  // Check if minimum spend requirement is met
  // When calculating for just one category for comparison, we need to consider if the total spending
  // across all categories would meet the minimum spend
  // This is a simplified approach where we assume the spending from just this category is sufficient
  // for meeting minimum spend requirements, which is not entirely accurate for real-world scenarios
  // but necessary for our comparison logic
  const totalSpend = Object.values(spending).reduce((sum, val) => sum + val, 0);
  
  // For a single category comparison, we need a better approach to handle min spend
  const isOneCategory = Object.keys(spending).length === 1;
  let meetsMinSpend = totalSpend >= card.minSpend;

  // If we're just comparing one category, assume the user can meet the min spend
  // This ensures high-reward cards with min spend requirements are properly considered
  if (isOneCategory && card.minSpend > 0) {
    // We'll still apply the check but with more leniency
    meetsMinSpend = true;
  }
  
  if (!meetsMinSpend && card.minSpend > 0) {
    return { 
      totalCashback, 
      totalMiles, 
      totalValue,
      categoryRewards,
      reachedLimit
    };
  }

  // First, find categories with combined limits
  const combinedLimitCategories = [];
  const normalCategories = [];
  
  Object.entries(spending).forEach(([categoryId, spendAmount]) => {
    if (spendAmount <= 0) return;
    
    const rewardInfo = card.rewards[categoryId];
    if (!rewardInfo) return;
    
    if (rewardInfo.combinedLimit) {
      combinedLimitCategories.push({ categoryId, spendAmount, rewardInfo });
    } else {
      normalCategories.push({ categoryId, spendAmount, rewardInfo });
    }
  });
  
  // Process combined limit categories first
  if (combinedLimitCategories.length > 0) {
    // Find the combined limit (all categories should have the same limit)
    const combinedLimit = combinedLimitCategories[0].rewardInfo.limit;
    
    // Sort by highest value per dollar
    combinedLimitCategories.sort((a, b) => {
      const aValue = a.rewardInfo.type === "miles" 
        ? a.rewardInfo.rate * mileValue / 100 
        : a.rewardInfo.rate / 100;
      const bValue = b.rewardInfo.type === "miles" 
        ? b.rewardInfo.rate * mileValue / 100 
        : b.rewardInfo.rate / 100;
      return bValue - aValue;
    });
    
    // Allocate spending to combined limit categories
    for (const { categoryId, spendAmount, rewardInfo } of combinedLimitCategories) {
      let effectiveSpend = spendAmount;
      let limitExceeded = false;
      
      // Check if adding this category would exceed the combined limit
      if (combinedLimitUsed + effectiveSpend > combinedLimit) {
        effectiveSpend = Math.max(0, combinedLimit - combinedLimitUsed);
        limitExceeded = true;
        reachedLimit = true;
      }
      
      combinedLimitUsed += effectiveSpend;
      
      let rewardValue;
      
      // For miles, the rate is miles per dollar, so we calculate directly 
      if (rewardInfo.type === "miles") {
        rewardValue = effectiveSpend * rewardInfo.rate; // Direct miles calculation
        totalMiles += rewardValue;
        totalValue += (rewardValue * mileValue / 100); // Convert miles to dollar value
      } else {
        // For cashback, the rate is percentage, so we calculate as before
        rewardValue = (effectiveSpend * rewardInfo.rate) / 100;
        totalCashback += rewardValue;
        totalValue += rewardValue;
      }
      
      // Track category-specific reward
      categoryRewards[categoryId] = {
        spendAmount,
        effectiveSpend,
        rewardType: rewardInfo.type,
        rewardRate: rewardInfo.rate,
        rewardValue,
        limitExceeded
      };
      
      // If we've hit the combined limit, no need to process further categories
      if (combinedLimitUsed >= combinedLimit) {
        break;
      }
    }
  }

  // Process normal categories
  normalCategories.forEach(({ categoryId, spendAmount, rewardInfo }) => {
    // Check if spending exceeds any category limits
    let effectiveSpend = spendAmount;
    let limitExceeded = false;
    
    if (rewardInfo.limit !== null && spendAmount > rewardInfo.limit) {
      effectiveSpend = rewardInfo.limit;
      limitExceeded = true;
      reachedLimit = true;
    }
    
    let rewardValue;
    
    // For miles, the rate is miles per dollar, so we calculate directly 
    if (rewardInfo.type === "miles") {
      rewardValue = effectiveSpend * rewardInfo.rate; // Direct miles calculation
      totalMiles += rewardValue;
      totalValue += (rewardValue * mileValue / 100); // Convert miles to dollar value
    } else {
      // For cashback, the rate is percentage, so we calculate as before
      rewardValue = (effectiveSpend * rewardInfo.rate) / 100;
      totalCashback += rewardValue;
      totalValue += rewardValue;
    }
    
    // Track category-specific reward
    categoryRewards[categoryId] = {
      spendAmount,
      effectiveSpend,
      rewardType: rewardInfo.type,
      rewardRate: rewardInfo.rate,
      rewardValue,
      limitExceeded
    };
  });
  
  return {
    totalCashback,
    totalMiles,
    totalValue,
    categoryRewards,
    reachedLimit
  };
};

/**
 * Find the optimal card combination based on spending patterns
 */
export const findOptimalCardCombination = (creditCards, spending, mileValue) => {
  // For each category, find the best card combination
  const bestCategoryCardsAllocation = {};
  const usedCards = new Set();
  const categoryTotals = {};
  
  // Initialize totals for each category
  Object.keys(spending).forEach(categoryId => {
    categoryTotals[categoryId] = {
      miles: 0,
      cashback: 0,
      value: 0
    };
    
    // Initialize category allocation structure
    bestCategoryCardsAllocation[categoryId] = [];
  });
  
  // Find the best card combination for each category
  Object.entries(spending).forEach(([categoryId, totalSpendAmount]) => {
    if (totalSpendAmount <= 0) return;
    
    // Create a list of cards sorted by their reward rate for this category
    const cardsForCategory = creditCards
      .filter(card => card.rewards[categoryId])
      .map(card => {
        const rewardInfo = card.rewards[categoryId];
        let valuePerDollar;
        
        if (rewardInfo.type === "miles") {
          valuePerDollar = (rewardInfo.rate * mileValue / 100);
        } else {
          valuePerDollar = (rewardInfo.rate / 100);
        }
        
        return {
          card,
          rewardInfo,
          valuePerDollar
        };
      })
      .sort((a, b) => b.valuePerDollar - a.valuePerDollar);
    
    // Allocate spending optimally across cards
    let remainingSpend = totalSpendAmount;
    
    // First pass: Allocate to cards with the best rates up to their limits
    for (const { card, rewardInfo } of cardsForCategory) {
      if (remainingSpend <= 0) break;
      
      // Skip cards that don't meet minimum spend requirements
      // (simplified check as the full check requires knowing total spend across all categories)
      if (card.minSpend > 0 && card.minSpend > totalSpendAmount) continue;
      
      let allocatedSpend = remainingSpend;
      let limitExceeded = false;
      
      // If this card has a limit, only allocate up to that limit
      if (rewardInfo.limit !== null) {
        if (remainingSpend > rewardInfo.limit) {
          allocatedSpend = rewardInfo.limit;
          limitExceeded = true;
        }
      }
      
      if (allocatedSpend <= 0) continue;
      
      // Calculate rewards for this allocation
      let rewardValue;
      if (rewardInfo.type === "miles") {
        rewardValue = allocatedSpend * rewardInfo.rate;
      } else {
        rewardValue = (allocatedSpend * rewardInfo.rate) / 100;
      }
      
      // Add allocation to the category's list of cards
      bestCategoryCardsAllocation[categoryId].push({
        card,
        rewardInfo,
        allocatedSpend,
        effectiveSpend: allocatedSpend,
        rewardValue,
        limitExceeded
      });
      
      // Track the card as used
      usedCards.add(card.id);
      
      // Reduce remaining spend
      remainingSpend -= allocatedSpend;
      
      // Update category totals
      if (rewardInfo.type === "miles") {
        categoryTotals[categoryId].miles += rewardValue;
        categoryTotals[categoryId].value += (rewardValue * mileValue / 100);
      } else {
        categoryTotals[categoryId].cashback += rewardValue;
        categoryTotals[categoryId].value += rewardValue;
      }
    }
  });
  
  // Group allocations by card
  const cardToCategories = {};
  
  // Process each category's allocations
  Object.entries(bestCategoryCardsAllocation).forEach(([categoryId, allocations]) => {
    allocations.forEach(allocation => {
      const cardId = allocation.card.id;
      
      if (!cardToCategories[cardId]) {
        cardToCategories[cardId] = {
          card: allocation.card,
          categories: [],
          reachedLimit: false
        };
      }
      
      cardToCategories[cardId].categories.push({
        id: categoryId,
        name: categoryId, // Will be replaced with actual name in component
        spend: allocation.allocatedSpend,
        effectiveSpend: allocation.effectiveSpend,
        value: allocation.rewardValue,
        rewardType: allocation.rewardInfo.type,
        rewardRate: allocation.rewardInfo.rate,
        limitExceeded: allocation.limitExceeded
      });
      
      if (allocation.limitExceeded) {
        cardToCategories[cardId].reachedLimit = true;
      }
    });
  });
  
  // Collect the full details of unique cards in our combination
  const optimalCardCombination = Object.values(cardToCategories).map(({ card, categories, reachedLimit }) => {
    // Calculate total rewards this card would earn across all its categories
    let totalMiles = 0;
    let totalCashback = 0;
    let totalValue = 0;
    
    categories.forEach(category => {
      if (category.rewardType === "miles") {
        totalMiles += category.value;
        totalValue += (category.value * mileValue / 100); // Convert miles to dollar value
      } else {
        totalCashback += category.value;
        totalValue += category.value;
      }
    });
    
    return {
      ...card,
      categories,
      totalMiles,
      totalCashback,
      totalValue,
      reachedLimit
    };
  });
  
  // Sort by total value (highest first)
  optimalCardCombination.sort((a, b) => b.totalValue - a.totalValue);
  
  // Calculate total rewards
  const totalRewards = Object.values(categoryTotals).reduce(
    (acc, categoryReward) => {
      return {
        miles: acc.miles + categoryReward.miles,
        cashback: acc.cashback + categoryReward.cashback,
        value: acc.value + categoryReward.value
      };
    },
    { miles: 0, cashback: 0, value: 0 }
  );
  
  // Store original spending amounts for each category
  optimalCardCombination.forEach(card => {
    card.categories.forEach(category => {
      category.totalCategorySpend = spending[category.id] || category.spend;
    });
  });
  
  return {
    cardCombination: optimalCardCombination,
    totalRewards: {
      miles: totalRewards.miles,
      cashback: totalRewards.cashback,
      totalValue: totalRewards.value
    }
  };
};

/**
 * Calculate alternative rewards (all miles or all cashback)
 */
export const calculateAlternativeRewards = (creditCards, spending, mileValue, preferredType) => {
  // Filter cards by preferred type
  const filteredCards = creditCards.filter(card => card.category === preferredType);
  
  // For each category, find the best card combination
  let totalMiles = 0;
  let totalCashback = 0;
  let totalValue = 0;
  
  Object.entries(spending).forEach(([categoryId, totalSpendAmount]) => {
    if (totalSpendAmount <= 0) return;
    
    // Create a list of cards sorted by their reward rate for this category
    const cardsForCategory = filteredCards
      .filter(card => card.rewards[categoryId])
      .map(card => {
        const rewardInfo = card.rewards[categoryId];
        let valuePerDollar;
        
        if (rewardInfo.type === "miles") {
          valuePerDollar = (rewardInfo.rate * mileValue / 100);
        } else {
          valuePerDollar = (rewardInfo.rate / 100);
        }
        
        return {
          card,
          rewardInfo,
          valuePerDollar
        };
      })
      .sort((a, b) => b.valuePerDollar - a.valuePerDollar);
    
    // Allocate spending optimally across cards
    let remainingSpend = totalSpendAmount;
    
    // First pass: Allocate to cards with the best rates up to their limits
    for (const { card, rewardInfo } of cardsForCategory) {
      if (remainingSpend <= 0) break;
      
      // Skip cards that don't meet minimum spend requirements
      if (card.minSpend > 0 && card.minSpend > totalSpendAmount) continue;
      
      let allocatedSpend = remainingSpend;
      
      // If this card has a limit, only allocate up to that limit
      if (rewardInfo.limit !== null) {
        if (remainingSpend > rewardInfo.limit) {
          allocatedSpend = rewardInfo.limit;
        }
      }
      
      if (allocatedSpend <= 0) continue;
      
      // Calculate rewards for this allocation
      let rewardValue;
      if (rewardInfo.type === "miles") {
        rewardValue = allocatedSpend * rewardInfo.rate;
        totalMiles += rewardValue;
        totalValue += (rewardValue * mileValue / 100);
      } else {
        rewardValue = (allocatedSpend * rewardInfo.rate) / 100;
        totalCashback += rewardValue;
        totalValue += rewardValue;
      }
      
      // Reduce remaining spend
      remainingSpend -= allocatedSpend;
    }
  });
  
  return {
    miles: totalMiles,
    cashback: totalCashback,
    totalValue
  };
};