// Singapore Credit card data with spending limits (updated 2025)
const creditCards = [
  {
    id: 1, 
    name: "DBS Woman's World Card",
    bank: "DBS",
    category: "Miles",
    rewards: {
      online: { type: "miles", rate: 4, limit: 1500 }, // 4 miles per dollar (mpd) on online spend, capped at $1500
      contactless: { type: "miles", rate: 0.4, limit: null }, // 0.4 mpd base on contactless
      dining: { type: "miles", rate: 0.4, limit: null },
      groceries: { type: "miles", rate: 0.4, limit: null },
      transport: { type: "miles", rate: 0.4, limit: null },
      other: { type: "miles", rate: 0.4, limit: null }
    },
    annualFee: 192.60,
    minSpend: 0,
    benefits: "• 4 miles per dollar on online transactions\n• No minimum spend requirement\n• Suitable for anyone regardless of gender\n• Great for online shopping and subscriptions",
    exclusions: "• 4 mpd capped at first $1,500 per month\n• Annual fee waiver upon request",
    link: "https://www.dbs.com.sg/personal/cards/credit-cards/dbs-womans-world-mastercard"
  },
  {
    id: 2,
    name: "Citi Rewards Card",
    bank: "Citibank",
    category: "Miles",
    rewards: {
      online: { type: "miles", rate: 4, limit: 1000 }, // 4 miles (10x points) on online retail, capped at $1000
      dining: { type: "miles", rate: 0.4, limit: null },
      groceries: { type: "miles", rate: 0.4, limit: null },
      transport: { type: "miles", rate: 0.4, limit: null },
      contactless: { type: "miles", rate: 0.4, limit: null },
      other: { type: "miles", rate: 0.4, limit: null }
    },
    annualFee: 192.60,
    minSpend: 0,
    benefits: "• 10X rewards (4 mpd) on clothes, bags, shoes\n• Online and offline shopping rewarded\n• No minimum spend requirement\n• 24-month validity on points",
    exclusions: "• 10X rewards capped at $1,000 per month\n• Excluded categories: travel, insurance, utilities, government services",
    link: "https://www.citibank.com.sg/credit-cards/rewards/citi-rewards-card/"
  },
  {
    id: 3,
    name: "UOB PRVI Miles",
    bank: "UOB",
    category: "Miles",
    rewards: {
      online: { type: "miles", rate: 1.4, limit: null },
      contactless: { type: "miles", rate: 1.4, limit: null },
      dining: { type: "miles", rate: 1.4, limit: null },
      groceries: { type: "miles", rate: 1.4, limit: null },
      transport: { type: "miles", rate: 1.4, limit: null },
      other: { type: "miles", rate: 1.4, limit: null }
    },
    annualFee: 256.80,
    minSpend: 0,
    benefits: "• 1.4 miles per dollar on all local spend\n• 2.4 miles per dollar on overseas spend\n• Up to 6 miles per dollar on major airlines and hotels\n• No minimum spend or category restrictions",
    exclusions: "• Annual fee waiver for first year\n• Slightly higher miles conversion fee",
    link: "https://www.uob.com.sg/personal/cards/credit-cards/travel/prvi-miles-visa-card.page"
  },
  {
    id: 4,
    name: "DBS Altitude",
    bank: "DBS",
    category: "Miles",
    rewards: {
      online: { type: "miles", rate: 1.2, limit: null },
      contactless: { type: "miles", rate: 1.2, limit: null },
      dining: { type: "miles", rate: 1.2, limit: null },
      groceries: { type: "miles", rate: 1.2, limit: null },
      transport: { type: "miles", rate: 1.2, limit: null },
      other: { type: "miles", rate: 1.2, limit: null }
    },
    annualFee: 192.60,
    minSpend: 0,
    benefits: "• 1.2 miles per dollar on local spend\n• 2 miles per dollar on overseas spend\n• 3 miles per dollar on online travel and flight bookings\n• Miles never expire\n• Two complimentary lounge visits per year",
    exclusions: "• Annual fee waiver for first year\n• No minimum spend required",
    link: "https://www.dbs.com.sg/personal/cards/credit-cards/altitude"
  },
  {
    id: 5,
    name: "OCBC 90°N Card",
    bank: "OCBC",
    category: "Miles",
    rewards: {
      online: { type: "miles", rate: 1.3, limit: null },
      contactless: { type: "miles", rate: 1.3, limit: null },
      dining: { type: "miles", rate: 1.3, limit: null },
      groceries: { type: "miles", rate: 1.3, limit: null },
      transport: { type: "miles", rate: 1.3, limit: null },
      other: { type: "miles", rate: 1.3, limit: null }
    },
    annualFee: 192.60,
    minSpend: 0,
    benefits: "• 1.3 miles per dollar on local spend\n• 2.1 miles per dollar on overseas spend\n• Miles never expire\n• No conversion fees",
    exclusions: "• Annual fee waiver for 2 years",
    link: "https://www.ocbc.com/personal-banking/cards/90n-visa-card"
  },
  {
    id: 6,
    name: "HSBC Revolution",
    bank: "HSBC",
    category: "Miles",
    rewards: {
      online: { type: "miles", rate: 4, limit: 1000 },
      dining: { type: "miles", rate: 0.4, limit: null },
      groceries: { type: "miles", rate: 0.4, limit: null },
      contactless: { type: "miles", rate: 0.4, limit: null },
      transport: { type: "miles", rate: 0.4, limit: null },
      other: { type: "miles", rate: 0.4, limit: null }
    },
    annualFee: 0,
    minSpend: 0,
    benefits: "• 4 miles per dollar on online spend\n• No minimum spend requirement\n• No annual fee",
    exclusions: "• Limited merchant categories for 4 mpd\n• 4 mpd capped at $1,000 per month for online spend",
    link: "https://www.hsbc.com.sg/credit-cards/products/revolution/"
  },
  {
    id: 7,
    name: "UOB One Card",
    bank: "UOB",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 5, limit: 2000 },
      groceries: { type: "cashback", rate: 5, limit: 2000 },
      transport: { type: "cashback", rate: 5, limit: 2000 },
      online: { type: "cashback", rate: 5, limit: 2000 },
      contactless: { type: "cashback", rate: 5, limit: 2000 },
      other: { type: "cashback", rate: 5, limit: 2000 }
    },
    annualFee: 192.60,
    minSpend: 2000,
    benefits: "• Up to 5% cashback on all spend with $2,000 spend\n• Up to 6% interest on UOB One Account deposits\n• Additional rebates at Shopee, Dairy Farm, BP, and Shell",
    exclusions: "• Tiered cashback: $500/month (1.67%), $1,000/month (3.33%), $2,000/month (5%)\n• Must make exactly 5 transactions per month\n• Annual fee waiver on request",
    link: "https://www.uob.com.sg/personal/cards/credit-cards/rebates/one-card.page"
  },
  {
    id: 8,
    name: "Citi Cash Back+ Card",
    bank: "Citibank",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 1.6, limit: null },
      groceries: { type: "cashback", rate: 1.6, limit: null },
      transport: { type: "cashback", rate: 1.6, limit: null },
      online: { type: "cashback", rate: 1.6, limit: null },
      contactless: { type: "cashback", rate: 1.6, limit: null },
      other: { type: "cashback", rate: 1.6, limit: null }
    },
    annualFee: 192.60,
    minSpend: 0,
    benefits: "• 1.6% unlimited cashback on all spend\n• No minimum spend requirement\n• No cashback cap\n• No category restrictions",
    exclusions: "• Annual fee waiver for first year\n• Not the highest rate but simplest to use",
    link: "https://www.citibank.com.sg/credit-cards/cashback/cash-back-plus-card/"
  },
  {
    id: 9,
    name: "Citi Cash Back Card",
    bank: "Citibank",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 8, limit: 312.5 }, // 8% cashback on dining, capped at $25 ($312.5 spend)
      groceries: { type: "cashback", rate: 8, limit: 312.5 }, // 8% cashback on groceries, capped at $25 ($312.5 spend)
      transport: { type: "cashback", rate: 8, limit: 312.5 }, // 8% cashback on transport, capped at $25 ($312.5 spend)
      online: { type: "cashback", rate: 0.25, limit: null },
      contactless: { type: "cashback", rate: 0.25, limit: null },
      other: { type: "cashback", rate: 0.25, limit: null }
    },
    annualFee: 192.60,
    minSpend: 800,
    benefits: "• 8% cashback on dining, groceries, and petrol\n• Additional petrol savings at Esso and Shell\n• Complimentary travel insurance",
    exclusions: "• Minimum spend of $800 per month\n• Each cashback category capped at $25 per month ($312.5 spend)\n• Annual fee waiver for first year",
    link: "https://www.citibank.com.sg/credit-cards/cashback/cash-back-card/"
  },
  {
    id: 10,
    name: "OCBC 365 Credit Card",
    bank: "OCBC",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 6, limit: 667 }, // 6% cashback on dining, capped as part of $80 total
      groceries: { type: "cashback", rate: 3, limit: 667 }, // 3% cashback on groceries, capped as part of $80 total
      transport: { type: "cashback", rate: 3, limit: 667 }, // 3% cashback on transport, capped as part of $80 total
      online: { type: "cashback", rate: 3, limit: 667 }, // 3% cashback on online, capped as part of $80 total
      contactless: { type: "cashback", rate: 0.3, limit: null },
      other: { type: "cashback", rate: 0.3, limit: null }
    },
    annualFee: 192.60,
    minSpend: 800,
    benefits: "• 6% cashback on dining\n• 3% on groceries, land transport, online travel, and recurring telco bills\n• Complimentary travel insurance",
    exclusions: "• Minimum spend of $800 per month\n• Total cashback capped at $80 per month\n• Annual fee waiver for 2 years",
    link: "https://www.ocbc.com/personal-banking/cards/365-cashback-credit-card"
  },
  {
    id: 11,
    name: "DBS Live Fresh",
    bank: "DBS",
    category: "Cashback",
    rewards: {
      online: { type: "cashback", rate: 5, limit: 500 }, // 5% cashback on online spend, capped at $25 ($500 spend)
      contactless: { type: "cashback", rate: 5, limit: 500 }, // 5% cashback on contactless, capped at $25 ($500 spend)
      dining: { type: "cashback", rate: 0.3, limit: null },
      groceries: { type: "cashback", rate: 0.3, limit: null },
      transport: { type: "cashback", rate: 0.3, limit: null },
      other: { type: "cashback", rate: 0.3, limit: null }
    },
    annualFee: 192.60,
    minSpend: 600,
    benefits: "• 5% cashback on online spend and contactless payments\n• Additional 5% on eco-friendly merchants (total 10%)\n• Complimentary eGiro service",
    exclusions: "• Minimum spend of $600 per month\n• Combined 5% cashback categories capped at $25 per month\n• Annual fee waiver for first year",
    link: "https://www.dbs.com.sg/personal/cards/credit-cards/live-fresh-dbs-visa-paywave-card"
  },
  {
    id: 12,
    name: "CIMB Visa Signature",
    bank: "CIMB",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 10, limit: 200 }, // 10% on dining, capped as part of $100 total
      online: { type: "cashback", rate: 10, limit: 200 }, // 10% on online spend, capped as part of $100 total
      contactless: { type: "cashback", rate: 0.2, limit: null },
      groceries: { type: "cashback", rate: 0.2, limit: null },
      transport: { type: "cashback", rate: 0.2, limit: null },
      other: { type: "cashback", rate: 0.2, limit: null }
    },
    annualFee: 0,
    minSpend: 800,
    benefits: "• 10% cashback on dining, groceries, online shopping, beauty and wellness\n• No annual fee for life\n• Complimentary travel insurance",
    exclusions: "• Minimum spend of $800 per month\n• Each category capped at $20 ($200 spend per category)\n• Total cashback capped at $100 per month",
    link: "https://www.cimb.com.sg/en/personal/banking-with-us/credit-cards/cimb-visa-signature.html"
  },
  {
    id: 13,
    name: "HSBC Visa Platinum",
    bank: "HSBC",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 5, limit: 1000 }, // 5% cashback on dining, capped at $1000 spend
      groceries: { type: "cashback", rate: 5, limit: 1000 }, // 5% cashback on groceries, capped at $1000 spend
      online: { type: "cashback", rate: 0.3, limit: null },
      contactless: { type: "cashback", rate: 0.3, limit: null },
      transport: { type: "cashback", rate: 0.3, limit: null },
      other: { type: "cashback", rate: 0.3, limit: null }
    },
    annualFee: 192.60,
    minSpend: 600,
    benefits: "• 5% cashback on dining and groceries\n• Free access to HSBC Entertainer mobile app\n• Fuel savings at Caltex\n• Complimentary travel insurance",
    exclusions: "• Minimum spend of $600 per month\n• Total cashback capped at $250 per month\n• Each category has $1000 monthly cap\n• Annual fee waiver for 2 years",
    link: "https://www.hsbc.com.sg/credit-cards/products/visa-platinum/"
  },
  {
    id: 14,
    name: "NTUC Plus! Visa Credit Card",
    bank: "OCBC",
    category: "Cashback",
    rewards: {
      groceries: { type: "cashback", rate: 7, limit: 714 }, // 7% at FairPrice, capped as part of $50 total
      dining: { type: "cashback", rate: 3, limit: null },
      transport: { type: "cashback", rate: 3, limit: null },
      online: { type: "cashback", rate: 3, limit: null },
      contactless: { type: "cashback", rate: 3, limit: null },
      other: { type: "cashback", rate: 3, limit: null }
    },
    annualFee: 0,
    minSpend: 400,
    benefits: "• 7% rebate at FairPrice stores and online\n• 3% rebate on recurring bills, transport, dining\n• No annual fee\n• LinkPoints conversion to cashback",
    exclusions: "• Minimum spend of $400 per month\n• FairPrice rebate capped at $50 per month\n• Best for regular FairPrice shoppers",
    link: "https://www.ocbc.com/personal-banking/cards/ntuc-plus-visa-credit-card"
  },
  {
    id: 15,
    name: "Standard Chartered Smart Card",
    bank: "Standard Chartered",
    category: "Cashback",
    rewards: {
      online: { type: "cashback", rate: 6, limit: 300 }, // 6% on online, capped at $18 ($300 spend)
      contactless: { type: "cashback", rate: 6, limit: 300 }, // 6% on contactless, capped at $18 ($300 spend)
      dining: { type: "cashback", rate: 6, limit: 300 }, // 6% on dining, capped at $18 ($300 spend)
      groceries: { type: "cashback", rate: 0.5, limit: null },
      transport: { type: "cashback", rate: 0.5, limit: null },
      other: { type: "cashback", rate: 0.5, limit: null }
    },
    annualFee: 192.60,
    minSpend: 500,
    benefits: "• 6% cashback on daily essentials (dining, groceries, etc.)\n• Easy to qualify minimum spend\n• Complimentary travel insurance\n• Earn rewards on top of cashback",
    exclusions: "• Minimum spend of $500 per month\n• Each category capped at $18 per month\n• Annual fee waiver for 2 years",
    link: "https://www.sc.com/sg/credit-cards/smart-credit-card/"
  },
  {
    id: 16,
    name: "MariBank Credit Card",
    bank: "MariBank",
    category: "Cashback",
    rewards: {
      dining: { type: "cashback", rate: 1.7, limit: null },
      groceries: { type: "cashback", rate: 1.7, limit: null },
      transport: { type: "cashback", rate: 1.7, limit: null },
      online: { type: "cashback", rate: 1.7, limit: null },
      contactless: { type: "cashback", rate: 1.7, limit: null },
      other: { type: "cashback", rate: 1.7, limit: null }
    },
    annualFee: 0,
    minSpend: 0,
    benefits: "• 1.7% unlimited cashback on all spend\n• No minimum spend requirement\n• No cashback cap\n• No annual fee\n• Digital-first banking experience",
    exclusions: "• No specific exclusions\n• All transactions are eligible for cashback",
    link: "https://www.maribank.com/"
  }
];

export default creditCards;