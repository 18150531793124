import React from 'react';

const SpendingForm = ({ categories, spending, handleSpendingChange }) => {
  return (
    <div className="spending-inputs">
      {categories.map(category => (
        <div className="input-group" key={category.id}>
          <label htmlFor={`spending-${category.id}`}>{category.name}</label>
          <div className="input-wrapper">
            <span className="currency-symbol">S$</span>
            <input
              id={`spending-${category.id}`}
              type="number"
              value={spending[category.id]}
              onChange={(e) => handleSpendingChange(category.id, e.target.value)}
              placeholder="0"
            />
          </div>
        </div>
      ))}
      <div className="spending-summary">
        <p>Monthly Total: <strong>S${Object.values(spending).reduce((sum, value) => sum + value, 0).toLocaleString()}</strong></p>
      </div>
    </div>
  );
};

export default SpendingForm;