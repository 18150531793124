import React, { useState, useEffect } from 'react';
import { IoSettingsOutline, IoConstructOutline } from 'react-icons/io5';
import './App.css';

// Components
import SpendingForm from './components/SpendingForm';
import RewardsSummary from './components/RewardsSummary';
import CardStrategy from './components/CardStrategy';
import SettingsModal from './components/SettingsModal';
import DeveloperSettingsModal from './components/DeveloperSettingsModal';

// Data
import creditCards from './data/creditCards';
import categories from './data/categories';

// Utils
import { findOptimalCardCombination, calculateAlternativeRewards } from './utils/calculationUtils';

function App() {
  // Default active cards (HSBC Revolution, HSBC Visa Platinum, UOB One Card, DBS Women's World Card, and MariBank Credit Card)
  const defaultActiveCards = [1, 6, 7, 13, 16]; // IDs of the default active cards

  // State for spending inputs
  const [spending, setSpending] = useState({
    dining: 1300,
    groceries: 800,
    transport: 500,
    online: 3000,
    contactless: 1000
  });
  
  // Mile value in cents (default: 1.5 cents per mile)
  const [mileValue, setMileValue] = useState(1.5);
  
  // Active cards
  const [activeCardIds, setActiveCardIds] = useState(defaultActiveCards);
  
  // Filtered credit cards based on active status
  const [filteredCards, setFilteredCards] = useState([]);
  
  // Modal states
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isDevSettingsModalOpen, setIsDevSettingsModalOpen] = useState(false);
  
  // Final recommended card combination
  const [cardCombination, setCardCombination] = useState([]);
  
  // Total potential rewards for optimal combination
  const [potentialRewards, setPotentialRewards] = useState({ 
    miles: 0, 
    cashback: 0,
    totalValue: 0 
  });
  
  // Alternative rewards (if you chose all miles or all cashback)
  const [alternativeRewards, setAlternativeRewards] = useState({
    miles: { miles: 0, cashback: 0, totalValue: 0 },
    cashback: { miles: 0, cashback: 0, totalValue: 0 }
  });

  // Update filtered cards whenever active card IDs change
  useEffect(() => {
    const filtered = creditCards.filter(card => activeCardIds.includes(card.id));
    setFilteredCards(filtered);
  }, [activeCardIds]);

  // Recalculate optimal card combination when spending, mile value, or filtered cards change
  useEffect(() => {
    calculateOptimalCardCombination();
  }, [spending, mileValue, filteredCards]);

  const handleSpendingChange = (category, value) => {
    setSpending(prev => ({
      ...prev,
      [category]: parseFloat(value) || 0
    }));
  };

  const handleMileValueChange = (value) => {
    setMileValue(value);
  };

  const handleActiveCardsChange = (activeCards) => {
    setActiveCardIds(activeCards);
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const closeSettingsModal = () => {
    setIsSettingsModalOpen(false);
  };

  const openDevSettingsModal = () => {
    setIsDevSettingsModalOpen(true);
  };

  const closeDevSettingsModal = () => {
    setIsDevSettingsModalOpen(false);
  };

  const calculateOptimalCardCombination = () => {
    // Get the optimal card combination based on spending and mile value
    const { cardCombination, totalRewards } = findOptimalCardCombination(
      filteredCards, // Use filtered cards instead of all credit cards
      spending,
      mileValue
    );
    
    // Calculate alternative rewards
    const milesOnlyRewards = calculateAlternativeRewards(
      filteredCards, // Use filtered cards for alternative calculations too
      spending,
      mileValue,
      "Miles"
    );
    
    const cashbackOnlyRewards = calculateAlternativeRewards(
      filteredCards,
      spending,
      mileValue,
      "Cashback"
    );
    
    // Enhance card combination with actual category names
    const enhancedCardCombination = cardCombination.map(card => {
      const enhancedCategories = card.categories.map(category => ({
        ...category,
        name: categories.find(c => c.id === category.id)?.name || category.id
      }));
      
      return {
        ...card,
        categories: enhancedCategories
      };
    });
    
    // Set state
    setCardCombination(enhancedCardCombination);
    setPotentialRewards(totalRewards);
    setAlternativeRewards({
      miles: milesOnlyRewards,
      cashback: cashbackOnlyRewards
    });
  };

  return (
    <div className="App">
      {/* Removed header for cleaner UI */}
      
      <div className="container">
        <div className="panel left-panel">
          <h2>Monthly Spending</h2>
          <SpendingForm 
            categories={categories} 
            spending={spending} 
            handleSpendingChange={handleSpendingChange} 
          />
        </div>
        
        <div className="panel right-panel">
          <div className="optimization-controls">
            <h2>
              FinTracker Optimizer
              <div className="control-buttons">
                <button className="settings-button" onClick={openSettingsModal}>
                  <IoSettingsOutline className="settings-icon" />
                </button>
                <button className="dev-settings-button" onClick={openDevSettingsModal}>
                  <IoConstructOutline className="dev-settings-icon" />
                </button>
              </div>
            </h2>
            <div className="mile-value-info">
              <span>Current mile value: <strong>{mileValue} cents</strong></span>
            </div>
          </div>
          
          <RewardsSummary 
            potentialRewards={potentialRewards} 
            alternativeRewards={alternativeRewards} 
          />
          
          {/* Optimal Card Combination Summary */}
          <div className="optimal-strategy">
            <h3>Optimal Card Strategy</h3>
            <CardStrategy cardCombination={cardCombination} />
          </div>
        </div>
      </div>
      
      <div className="disclaimer">
        <small>Disclaimer: This is a hobby project. The information provided is for general informational purposes only. FinTracker is not liable for any decisions made based on this tool.</small>
      </div>
      
      <SettingsModal 
        isOpen={isSettingsModalOpen}
        onClose={closeSettingsModal}
        currentMileValue={mileValue}
        onSave={handleMileValueChange}
      />
      
      <DeveloperSettingsModal
        isOpen={isDevSettingsModalOpen}
        onClose={closeDevSettingsModal}
        creditCards={creditCards}
        activeCards={activeCardIds}
        onSave={handleActiveCardsChange}
      />
    </div>
  );
}

export default App;