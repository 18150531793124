import React, { useState } from 'react';
import { IoWarningOutline, IoChevronDownOutline, IoChevronUpOutline, IoInformationCircleOutline } from 'react-icons/io5';

const CardStrategy = ({ cardCombination }) => {
  const [expandedCards, setExpandedCards] = useState({});

  const toggleCardDetails = (cardId) => {
    setExpandedCards(prev => ({
      ...prev,
      [cardId]: !prev[cardId]
    }));
  };

  return (
    <div className="strategy-summary">
      {cardCombination.map(card => (
        <div className="card-strategy" key={card.id}>
          <div className="card-strategy-header">
            <span className="bank-logo">{card.bank.charAt(0)}</span>
            <span className="card-strategy-name">{card.name}</span>
          </div>
          
          <div className="category-list">
            {card.categories.map(category => {              
              return (
                <div className="category-item" key={category.id}>
                  <div className="category-info">
                    <span className="category-name">{category.name}</span>
                    <span className="category-spend">
                      {category.limitExceeded 
                        ? <span>S${category.spend.toLocaleString()} of S$${category.totalCategorySpend.toLocaleString()}</span>
                        : (category.spend < category.totalCategorySpend 
                            ? <span>S${category.spend.toLocaleString()} of S$${category.totalCategorySpend.toLocaleString()}</span>
                            : `S$${category.spend.toLocaleString()}`)
                      }
                    </span>
                  </div>
                  <div className="category-reward">
                    {category.rewardType === "miles" ? 
                      `${category.rewardRate} mpd` : 
                      `${category.rewardRate}% cashback`}
                  </div>
                </div>
              );
            })}
          </div>
          
          <div className="card-strategy-total">
            {card.totalMiles > 0 && (
              <span className="card-total-miles">
                {Math.round(card.totalMiles).toLocaleString()} miles
              </span>
            )}
            {card.totalCashback > 0 && (
              <span className="card-total-cashback">
                S${card.totalCashback.toFixed(2)} cashback
              </span>
            )}
          </div>
          
          <div className="card-details-toggle" onClick={() => toggleCardDetails(card.id)}>
            <div className="toggle-content">
              <IoInformationCircleOutline className="info-toggle-icon" />
              <span>{expandedCards[card.id] ? "Less info about card" : "More info about card"}</span>
            </div>
            {expandedCards[card.id] ? 
              <IoChevronUpOutline className="chevron-icon" /> : 
              <IoChevronDownOutline className="chevron-icon" />
            }
          </div>
          
          {expandedCards[card.id] && (
            <div className="card-details-content">
              <div className="card-rewards">
                <p>Annual Fee: <strong>S${card.annualFee.toFixed(2)}</strong></p>
                {card.minSpend > 0 && (
                  <p>Min. Monthly Spend: <strong>S${card.minSpend}</strong></p>
                )}
              </div>
              
              <div className="card-benefits">
                <p>Benefits:</p>
                <pre>{card.benefits}</pre>
              </div>
              
              <div className="card-exclusions">
                <p>Exclusions & Limits:</p>
                <pre>{card.exclusions}</pre>
              </div>
              
              <div className="card-link">
                <a href={card.link} target="_blank" rel="noopener noreferrer">View Card Details</a>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CardStrategy;