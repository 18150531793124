import React from 'react';

const RewardsSummary = ({ potentialRewards, alternativeRewards }) => {
  return (
    <div className="rewards-summary">
      <p>Potential Monthly Rewards:</p>
      <div className="rewards-grid">
        <div className="reward-column current">
          {potentialRewards.miles > 0 && (
            <p className="reward-value">
              <span className="reward-icon">✈️</span>
              <strong>{Math.round(potentialRewards.miles).toLocaleString()} miles</strong>
            </p>
          )}
          {potentialRewards.cashback > 0 && (
            <p className="reward-value">
              <span className="reward-icon">💰</span>
              <strong>S${potentialRewards.cashback.toFixed(2)} cashback</strong>
            </p>
          )}
          <p className="reward-value total-value">
            <span className="reward-icon">💎</span>
            <strong>S${potentialRewards.totalValue.toFixed(2)} value</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RewardsSummary;