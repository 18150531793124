import React, { useState } from 'react';

const SettingsModal = ({ isOpen, onClose, currentMileValue, onSave }) => {
  const [mileValue, setMileValue] = useState(currentMileValue);

  const handleSave = () => {
    onSave(parseFloat(mileValue));
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Settings</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <div className="settings-form">
            <div className="form-group">
              <label htmlFor="mileValue">Value per mile (in cents)</label>
              <div className="input-wrapper small">
                <input
                  id="mileValue"
                  type="number"
                  step="0.1"
                  min="0.1"
                  max="10"
                  value={mileValue}
                  onChange={(e) => setMileValue(e.target.value)}
                />
                <span className="input-suffix">¢</span>
              </div>
              <div className="form-help">
                This is the value you place on each airline mile, used to compare miles-based rewards with cashback.
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="button secondary" onClick={onClose}>Cancel</button>
          <button className="button primary" onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;