import React, { useState } from 'react';

const DeveloperSettingsModal = ({ isOpen, onClose, creditCards, activeCards, onSave }) => {
  const [activeCardIds, setActiveCardIds] = useState(activeCards);

  const handleToggleCard = (cardId) => {
    setActiveCardIds(prev => {
      if (prev.includes(cardId)) {
        return prev.filter(id => id !== cardId);
      } else {
        return [...prev, cardId];
      }
    });
  };

  const handleSave = () => {
    onSave(activeCardIds);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content dev-settings">
        <div className="modal-header">
          <h3>Developer Settings</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <div className="settings-form">
            <p className="settings-description">
              Select which cards should be active in the card recommendation system:
            </p>
            <div className="card-list">
              {creditCards.map(card => (
                <div className="card-toggle" key={card.id}>
                  <label className="toggle-container">
                    <input
                      type="checkbox"
                      checked={activeCardIds.includes(card.id)}
                      onChange={() => handleToggleCard(card.id)}
                    />
                    <span className="toggle-checkmark"></span>
                    <div className="card-toggle-info">
                      <span className="card-toggle-name">{card.name}</span>
                      <span className="card-toggle-bank">{card.bank}</span>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="button secondary" onClick={onClose}>Cancel</button>
          <button className="button primary" onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default DeveloperSettingsModal;